
import client from '../axiosInstance'

export async function loginUser(dispatch, loginPayload) {

	try {
		dispatch({ type: 'REQUEST_LOGIN' });
		let response =await client.post("/auth/login/", loginPayload)
                .then(function (response) {
                    
                    return response.data
                })
                .catch(function (error) {
                    if (error.response) {
                
                        return error.response
                    } 
                    }
            )
     
		let data = await response;
        
		if (data.data.status_code == 401) {
		
			dispatch({ type: 'LOGIN_ERROR', error: data.data.data});
			console.log(data.data.data);
			return null;

		}else{
			dispatch({ type: 'LOGIN_SUCCESS', payload: data.data });
			localStorage.setItem('currentUser', JSON.stringify(data.data));
			
			return data;
		}

	} catch (error) {
		dispatch({ type: 'LOGIN_ERROR', error: error });
		console.log(error);
		return null;
	}
}

export async function logout(dispatch) {
	dispatch({ type: 'LOGOUT' });
	localStorage.removeItem('currentUser');
	localStorage.removeItem('token');
}