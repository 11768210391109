import React, { createContext, useEffect, useState } from 'react';
import client from 'src/axiosInstance';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState(null);
  const [dashboardData,setDashboardData]=useState(null)

  useEffect(() => {
    const pollingInterval = 5000; // Polling interval
    let token = JSON.parse(localStorage.getItem("currentUser"));

    const fetchUsers = async () => {
      try {
        const response = await client.get('admin/admin/users/list/', {
          headers: {
            'x-access-token': token.access_token,
          },
        });
        setUsers(response.data.data);
        setError(null); // Reset error if successful
      } catch (error) {
        console.error(error);
        setError('Error! Failed to fetch users.');
      }
    };

    const fetchLatestUsers = async () => {
      try {
        const response = await client.get('/admin/admin/users/list/', {
          headers: {
            'x-access-token': token.access_token,
          },
        });

        if (response.data && response.data.length > 0) {
          setUsers(response.data.data);
        }

        setError(null);
      } catch (error) {
        console.error(error);
        setError('Error! Failed to fetch latest users.');
      }
    };

    const intervalId = setInterval(fetchLatestUsers, pollingInterval);

    // Fetch initial users and clear interval on component unmount
    Promise.all([fetchUsers(), clearInterval(intervalId)]);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const fetchUser = async (userId) => {
    let token = JSON.parse(localStorage.getItem("currentUser"));
    try {
      const response = await client.get(`admin/admin/users/list/${userId}/`, {
        headers: {
          'x-access-token': token.access_token,
        },
      });
      setSelectedUser(response.data.data);
      setError(null); // Reset error if successful
    } catch (error) {
      console.error(error);
      setError('Error! Something went wrong');
    }
  };

  const getDashboardData = async () => {
    let token = JSON.parse(localStorage.getItem("currentUser"));
    try {
      const response = await client.get(`/admin/admin/dashboard`, {
        headers: {
          'x-access-token': token.access_token,
        },
      });
      setDashboardData(response.data.data);
      setError(null); // Reset error if successful
    } catch (error) {
      console.error(error);
      setError('Error! Something went wrong');
    }
  };
  const handleCancelError = () => {
    setError(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'GMT'
    };

    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate.replace(' GMT', '');
  };

  return (
    <UserContext.Provider value={{
      users,
      selectedUser,
      fetchUser,
      error,
      handleCancelError,
      formatDate,
      setSelectedUser,
      dashboardData,
      getDashboardData
    }}>
      {children}
    </UserContext.Provider>
  );
};
