import axios from "axios";
import { BASE_URL } from "./config/setting";

const client = axios.create({
  baseURL: BASE_URL,

  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

// const client = axios.create({
//   baseURL: "http://127.0.0.1:5000/" ,
//       headers: {
//         'Access-Control-Allow-Origin': '*',
//         'Content-Type': 'application/json',
//       },
// });

export default client;
