import React, { Component, Suspense,useContext } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import {useAuthState} from "./context/index";
import "./scss/style.scss";
import './index.css'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/auth/Login"));
// const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
// const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));



function App() {
  const { loading, token } = useAuthState();
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            {!token ? (
              <>
                <Route
                  exact
                  path=""
                  name="Login Page"
                  element={<Login />}
                />
                 <Route
                  exact
                  path="/login"
                  name="Login Page"
                  element={<Login />}
                />
                <Route
                  exact
                  path="*"
                  name="Page 404"
                  element={<Page404 />}
                />
              </>
            ) : (
              <Route path="*" name="Home" element={<DefaultLayout />} />
            )}
          </Routes>
        </Suspense>
      </HashRouter>
    );
  
}

export default App;
